<template>
  <div class="map">
    <div class="backTitle" style="color: #3de7c9; font-size: 18px">
      <span @click="backChinaMap">全国</span>
      <span v-if="stitle" @click="backTitle"> >>> {{ stitle }}</span
      ><span v-if="ctitle"> >>> {{ ctitle }}</span>
    </div>
    <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options" :height="height" :width="width" />
  </div>
</template>

<script>
import Echart from '@/common/echart'
// import { create, mount } from 'sortablejs'
export default {
  data() {
    return {
      options: {},
      selectName: '全国',
      currentData: this.cdata,
      ctitle: '',
      stitle: '',
      backData1: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: '河北省',
          value: 23,
          data: [
            { name: '石家庄市', value: 0 },
            { name: '唐山市', value: 0 },
            { name: '廊坊市', value: 0 },
            { name: '张家口市', value: 0 },
            { name: '邯郸市', value: 0 },
            { name: '保定市', value: 0 },
            { name: '承德市', value: 0 },
            {
              name: '秦皇岛市',
              value: 23,
              data: [
                { name: '海港区', value: 5, data: [{}] },
                { name: '山海关区', value: 3, data: [{}] },
                { name: '北戴河区', value: 3, data: [{}] },
                { name: '抚宁区', value: 3, data: [{}] },
                { name: '青龙满族自治县', value: 3, data: [{}] },
                { name: '卢龙县', value: 3, data: [{}] },
                { name: '昌黎县', value: 3, data: [{}] }
              ]
            },
            { name: '邢台市', value: 0 },
            { name: '沧州市', value: 0 },
            { name: '衡水市', value: 0 }
          ]
          // elseData: {
          //   // 这里放置地图 tooltip 里想显示的数据
          // },
        },
        {
          name: '山东省',
          value: 0
        },
        {
          name: '辽宁省',
          value: 0
        },
        {
          name: '广东省',
          value: 0
        },
        {
          name: '浙江省',
          value: 0
        },
        {
          name: '湖北省',
          value: 0
        },
        {
          name: '湖南省',
          value: 0
        },
        {
          name: '香港',
          value: 0
        },
        {
          name: '澳门',
          value: 0
        },
        {
          name: '北京市',
          value: 0
        },
        {
          name: '天津市',
          value: 0
        },
        {
          name: '安徽省',
          value: 0
        },
        {
          name: '黑龙江省',
          value: 0
        },
        {
          name: '吉林省',
          value: 0
        },
        {
          name: '内蒙古自治区',
          value: 0
        },
        {
          name: '山西省',
          value: 0
        },
        {
          name: '河南省',
          value: 0
        },
        {
          name: '四川省',
          value: 0
        },
        {
          name: '重庆市',
          value: 0
        },
        {
          name: '海南省',
          value: 0
        },
        {
          name: '贵州省',
          value: 0
        },
        {
          name: '云南省',
          value: 0
        },
        {
          name: '江西省',
          value: 0
        },
        {
          name: '福建省',
          value: 0
        },
        {
          name: '江苏省',
          value: 0
        },
        {
          name: '台湾省',
          value: 0
        },
        {
          name: '上海市',
          value: 0
        },
        {
          name: '陕西省',
          value: 0
        },
        {
          name: '宁夏回族自治区',
          value: 0
        },
        {
          name: '甘肃省',
          value: 0
        },
        {
          name: '青海省',
          value: 0
        },
        {
          name: '西藏自治区',
          value: 0
        },
        {
          name: '新疆维吾尔自治区',
          value: 0
        },
        {
          name: '广西壮族自治区',
          value: 0
        }
      ]
    }
  },
  components: {
    Echart
  },
  props: {
    cdata: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: () => '800px'
    },
    width: {
      type: String,
      default: () => '100%'
    }
  },
  watch: {
    cdata: {
      handler(newData) {
        //设置点的位置(经纬度)
        //const geoCoordMap = {
        // 卢龙县: [118.898343, 39.899951, 20],
        // 昌黎县: [119.176557, 39.712645, 20],
        // 抚宁县: [119.253416, 39.88407, 20],
        // 青龙县: [118.959931, 40.416529, 20]
        //}
        // let seriesData = [
        //   {
        //     name: '卢龙县'
        //   },
        //   {
        //     name: '昌黎县'
        //   },
        //   {
        //     name: '抚宁县'
        //   },
        //   {
        //     name: '青龙县'
        //   }
        // ]
        // let convertData = function (data) {
        //   let scatterData = [];
        //   for (var i = 0; i < data.length; i++) {
        //     var geoCoord = geoCoordMap[data[i].name];
        //     if (geoCoord) {
        //       scatterData.push({
        //         name: data[i].name,
        //         value: geoCoord.concat(data[i].value),
        //       });
        //     }
        //   }
        //   return scatterData;
        // };
        this.options = {
          showLegendSymbol: true,
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 14,
              lineHeight: 22
            },
            position: (point) => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20]
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            formatter: (params) => {
              return `<div style="font-weight: bold;font-size: 16px;color: #3de7c9"> ${params.data.name} </div>
                        <div style="padding-top: 5px;"> <span>老人数量：${params.data.value}</span>`
            }
          },
          visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)']
            }
          },
          // 底部背景
          geo: {
            show: false,
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            top: '10%',
            left: '15%',
            map: this.selectName,
            roam: false,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,0,0,0)',
                shadowColor: 'rgba(7,114,204, .8)',
                shadowOffsetX: 5,
                shadowOffsetY: 5
              },
              emphasis: {
                areaColor: '#00aeef'
              }
            }
          },
          series: [
            {
              name: '相关指数',
              type: 'map',
              aspectScale: 0.85, //长宽比
              zoom: 1.2,
              mapType: this.selectName, // 自定义扩展图表类型
              top: '10%',
              left: '15%',
              itemStyle: {
                normal: {
                  color: 'red',
                  areaColor: 'rgba(19,54,162, .5)',
                  borderColor: 'rgba(0,242,252,.3)',
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: '#00f2fc'
                },
                emphasis: {
                  areaColor: '#4f7fff',
                  borderColor: 'rgba(0,242,252,.6)',
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: '#00f2fc'
                }
              },
              label: {
                formatter: (params) => `${params.name}`,
                show: true,
                position: 'insideRight',
                textStyle: {
                  fontSize: 14,
                  color: '#efefef'
                },
                emphasis: {
                  textStyle: {
                    color: '#fff'
                  }
                }
              },

              data: newData
            }
            // {
            //   type: "effectScatter",
            //   coordinateSystem: "geo",
            //   symbolSize: 7,
            //   effectType: "ripple",
            //   legendHoverLink: false,
            //   showEffectOn: "render",
            //   rippleEffect: {
            //     period: 4,
            //     scale: 2.5,
            //     brushType: "stroke",
            //   },
            //   zlevel: 1,
            //   itemStyle: {
            //     normal: {
            //       color: "#99FBFE",
            //       shadowBlur: 5,
            //       shadowColor: "#fff",
            //     },
            //   },
            //   data: convertData(seriesData),
            // },
          ]
        }
        // 重新选择区域
        this.handleMapRandomSelect()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // 页面加载完毕监听地图点击事件
    this.clickMap()
  },

  methods: {
    // loadOptions() {
    //   //设置点的位置(经纬度)
    //   const geoCoordMap = {
    //     // 卢龙县: [118.898343, 39.899951, 20],
    //     // 昌黎县: [119.176557, 39.712645, 20],
    //     // 抚宁县: [119.253416, 39.88407, 20],
    //     // 青龙县: [118.959931, 40.416529, 20]
    //   };
    //   let seriesData = [
    //     // {
    //     //   name: "卢龙县",
    //     // },
    //     // {
    //     //   name: "昌黎县",
    //     // },
    //     // {
    //     //   name: "抚宁县",
    //     // },
    //     // {
    //     //   name: "青龙县",
    //     // },
    //   ];
    //   let convertData = function (data) {
    //     let scatterData = [];
    //     for (var i = 0; i < data.length; i++) {
    //       var geoCoord = geoCoordMap[data[i].name];
    //       if (geoCoord) {
    //         scatterData.push({
    //           name: data[i].name,
    //           value: geoCoord.concat(data[i].value),
    //         });
    //       }
    //     }
    //     return scatterData;
    //   };
    //   this.options = {
    //     showLegendSymbol: true,
    //     tooltip: {
    //       trigger: "item",
    //       textStyle: {
    //         fontSize: 14,
    //         lineHeight: 22,
    //       },
    //       position: (point) => {
    //         // 固定在顶部
    //         return [point[0] + 50, point[1] - 20];
    //       },
    //       // 如果需要自定义 tooltip样式，需要使用formatter
    //       formatter: (params) => {
    //         return `<div style="font-weight: bold;font-size: 16px;color: #3de7c9"> ${params.data.name} </div>
    //                     <div style="padding-top: 5px;"> <span>老人数量：${params.data.value}</span>`;
    //       },
    //     },
    //     visualMap: {
    //       min: 0,
    //       max: 10,
    //       show: false,
    //       seriesIndex: 0,
    //       // 颜色
    //       inRange: {
    //         color: ["rgba(41,166,206, .5)", "rgba(69,117,245, .9)"],
    //       },
    //     },
    //     // 底部背景
    //     geo: {
    //       show: false,
    //       aspectScale: 0.85, //长宽比
    //       zoom: 1.2,
    //       top: "20%",
    //       left: "10%",
    //       map: this.selectName,
    //       roam: false,
    //       itemStyle: {
    //         normal: {
    //           areaColor: "rgba(0,0,0,0)",
    //           shadowColor: "rgba(7,114,204, .8)",
    //           shadowOffsetX: 5,
    //           shadowOffsetY: 5,
    //         },
    //         emphasis: {
    //           areaColor: "#00aeef",
    //         },
    //       },
    //     },
    //     series: [
    //       {
    //         name: "相关指数",
    //         type: "map",
    //         aspectScale: 0.85, //长宽比
    //         zoom: 1.2,
    //         mapType: this.selectName, // 自定义扩展图表类型
    //         top: "20%",
    //         left: "10%",
    //         itemStyle: {
    //           normal: {
    //             color: "red",
    //             areaColor: "rgba(19,54,162, .5)",
    //             borderColor: "rgba(0,242,252,.3)",
    //             borderWidth: 1,
    //             shadowBlur: 7,
    //             shadowColor: "#00f2fc",
    //           },
    //           emphasis: {
    //             areaColor: "#4f7fff",
    //             borderColor: "rgba(0,242,252,.6)",
    //             borderWidth: 2,
    //             shadowBlur: 10,
    //             shadowColor: "#00f2fc",
    //           },
    //         },
    //         label: {
    //           formatter: (params) => `${params.name}`,
    //           show: true,
    //           position: "insideRight",
    //           textStyle: {
    //             fontSize: 14,
    //             color: "#efefef",
    //           },
    //           emphasis: {
    //             textStyle: {
    //               color: "#fff",
    //             },
    //           },
    //         },

    //         data: this.currentData,
    //       },
    //       {
    //         type: "effectScatter",
    //         coordinateSystem: "geo",
    //         symbolSize: 7,
    //         effectType: "ripple",
    //         legendHoverLink: false,
    //         showEffectOn: "render",
    //         rippleEffect: {
    //           period: 4,
    //           scale: 2.5,
    //           brushType: "stroke",
    //         },
    //         zlevel: 1,
    //         itemStyle: {
    //           normal: {
    //             color: "#99FBFE",
    //             shadowBlur: 5,
    //             shadowColor: "#fff",
    //           },
    //         },
    //         data: convertData(seriesData),
    //       },
    //     ],
    //   };
    //   // 重新选择区域
    //   this.handleMapRandomSelect();
    // },
    clickMap() {
      const map = this.$refs.centreLeft2ChartRef.chart
      map.on('click', (params) => {
        this.selectName = params.name
        // console.log(params.name)

        const res = this.cdata.filter((item) => item.name === params.name)
        // console.log(res)
        if (res[0].data) {
          if (this.stitle) {
            this.ctitle = params.name
          } else {
            this.stitle = params.name
          }
          const mapData = res[0].data
          this.$emit('chartData', mapData)
          //包含区县说明最底层 就可以跳转到详情  显示坐标点 伤游问题
          // const isjump = params.name.some((item) => item.includes('区'))

          if (params.name.includes('区') || params.name.includes('县')) {
            this.$router.push({ name: 'ylMap', params: { district: params.name } })
          }
        }
      })
    },
    // 开启定时器
    startInterval() {
      const _self = this
      // 应通过接口获取配置时间，暂时写死5s
      const time = 2000
      if (this.intervalId !== null) {
        clearInterval(this.intervalId)
      }
      this.intervalId = setInterval(() => {
        _self.reSelectMapRandomArea()
      }, time)
    },
    // 重新随机选中地图区域
    reSelectMapRandomArea() {
      const length = 9
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart
          let index = Math.floor(Math.random() * length)
          while (index === this.preSelectMapIndex || index >= length) {
            index = Math.floor(Math.random() * length)
          }
          map.dispatchAction({
            type: 'mapUnSelect',
            seriesIndex: 0,
            dataIndex: this.preSelectMapIndex
          })
          map.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: index
          })
          map.dispatchAction({
            type: 'mapSelect',
            seriesIndex: 0,
            dataIndex: index
          })
          this.preSelectMapIndex = index
        } catch (error) {
          console.log(error)
        }
      })
    },
    handleMapRandomSelect() {
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart
          const _self = this
          setTimeout(() => {
            _self.reSelectMapRandomArea()
          }, 0)
          // 移入区域，清除定时器、取消之前选中并选中当前
          map.on('mouseover', function (params) {
            clearInterval(_self.intervalId)
            map.dispatchAction({
              type: 'mapUnSelect',
              seriesIndex: 0,
              dataIndex: _self.preSelectMapIndex
            })
            map.dispatchAction({
              type: 'mapSelect',
              seriesIndex: 0,
              dataIndex: params.dataIndex
            })
            _self.preSelectMapIndex = params.dataIndex
          })
          // 移出区域重新随机选中地图区域，并开启定时器
          map.on('globalout', function () {
            _self.reSelectMapRandomArea()
            _self.startInterval()
          })
          this.startInterval()
        } catch (error) {
          console.log(error)
        }
      })
    },
    backChinaMap() {
      this.selectName = '全国'
      this.stitle = ''
      this.ctitle = ''
      // this.cdata = this.backData1
      this.$emit('quanguoData', this.backData1)
      // this.$router.go(0)
    },
    backTitle() {
      this.selectName = this.stitle
      this.ctitle = ''
      const res = this.backData1.filter((item) => item.name === this.stitle)
      // console.log('父级：', res[0])

      // this.cdata = res[0].data
      this.$emit('shengData', res[0].data)
    }
  }
}
</script>
<style lang="less" scoped>
.map {
  position: relative;
  .backTitle {
    span {
      cursor: pointer;
    }
  }
}
</style>
